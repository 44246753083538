import { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthenticatedRoute } from './components/AuthenticatedRoute';
import { Login } from './components/Login';
import { GatewayDebugger } from './components/GatewayDebugger';

function App() {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    async function fetchConfig() {
      try {
        const response = await fetch('/config.json');  
        const configData = await response.json();
        setConfig(configData);
        Amplify.configure({
          Auth: {
            Cognito: {
              userPoolClientId: configData.VITE_USER_POOL_CLIENT_ID,
              userPoolId: configData.VITE_USER_POOL_ID,
              signUpVerificationMethod: 'code',
              loginWith: {
                oauth: {
                  domain: configData.VITE_COGNITO_DOMAIN,
                  scopes: ['openid', 'email', 'profile'],
                  redirectSignIn: [configData.VITE_REDIRECT_SIGN_IN],
                  redirectSignOut: [configData.VITE_REDIRECT_SIGN_OUT],
                  responseType: 'code',
                }
              }
            }
          },
          API: {
            REST: {
              debuggerApi: {
                endpoint: configData.VITE_API_URL
              }
            }
          }
        });
      } catch (error) {
        console.error('Failed to load config:', error);
      }
    }

    fetchConfig();
  }, []);

  if (!config) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login config={config} />} />
        <Route
          path="/"
          element={
            <AuthenticatedRoute>
              <GatewayDebugger />
            </AuthenticatedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;