import { Box, Flex, Image, Button, useBreakpointValue, Container } from '@chakra-ui/react';
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/Kalmar_logo.png'; // Make sure to add your logo to assets folder

export const Navigation = () => {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/login'); // Redirect to login page
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Responsive logo size
  const logoSize = useBreakpointValue({ base: '40px', md: '40px' });

  return (
    <Box 
      as="nav" 
      bg="#2c112d" 
      py={4}
    >
      <Container maxW="container.md">
        <Flex justify="space-between" align="center">
          <Image
            src={logo}
            alt="Kalmar Logo"
            height={logoSize}
            objectFit="contain"
          />
          <Button
            variant="solid"
            bg="#2c112d"
            color="white"
            fontWeight="bold"
            onClick={handleSignOut}
          >
            Sign Out
          </Button>
        </Flex>
      </Container>
    </Box>
  );
};