import React from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import { signIn, signInWithRedirect, getCurrentUser } from 'aws-amplify/auth';
import { useState, useEffect } from 'react';
import { Logo } from './Logo';

interface LoginProps {
  config: {
    VITE_COGNITO_IDENTITY_ENABLED?: boolean;
  };
}

export function Login({ config }: LoginProps) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function checkAuthState() {
      try {
        await getCurrentUser();
        navigate('/');
      } catch {
        // User is not authenticated
      }
    }
    checkAuthState();
  }, [navigate]);

  const signInWithEmail = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const user = await signIn({ username: email, password });
      console.log("User signed in:", user);
      navigate('/');
    } catch (error) {
      console.error("Error signing in:", error);
      setError("Invalid email or password. Please try again.");
      setIsLoading(false);
    }
  };

  const signInWithGoogle = async () => {
    try {
      setIsLoading(true);
      await signInWithRedirect({ provider: 'Google' });
    } catch (error) {
      console.error('Error signing in:', error);
      setError("Error signing in with Google. Please try again.");
      setIsLoading(false);
    }
  };

  return (
    <Container maxW="md" py={{ base: '12', md: '24' }}>
      <Card.Root w="full" bg="bg.surface">
        <Card.Body>
          <Stack gap="8" align="center">
            {/* Header */}
            <Stack gap="6" align="center">
              <Box bg="white" p="2" borderRadius="md">
                <Logo />
              </Box>
              <Stack gap="3" align="center">
                <Heading size="lg">Sign in</Heading>
                <Text color="fg.muted">Gateway Diagnostics Tool</Text>
              </Stack>
            </Stack>

            {/* Sign in Options */}
            <Stack gap="6" w="full">
              {config.VITE_COGNITO_IDENTITY_ENABLED === true && (
                <>
                  <Box>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{
                        width: '100%',
                        padding: '8px',
                        borderRadius: '4px',
                        border: '1px solid #E2E8F0'
                      }}
                    />
                  </Box>
                  <Box>
                    <input
                      type="password"
                      placeholder="Enter your password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{
                        width: '100%',
                        padding: '8px',
                        borderRadius: '4px',
                        border: '1px solid #E2E8F0'
                      }}
                    />
                  </Box>
                  <Button
                    onClick={signInWithEmail}
                    colorScheme="blue"
                    w="full"
                    isLoading={isLoading}
                  >
                    Sign in with Email
                  </Button>

                  {error && (
                    <Text color="red.500" textAlign="center">
                      {error}
                    </Text>
                  )}

                  <Text textAlign="center" color="fg.muted">OR</Text>
                </>
              )}

              {/* Google Sign in */}
              <Button
                onClick={signInWithGoogle}
                variant="outline"
                w="full"
              >
                {isLoading ? "Signing in..." : (
                  <>
                    <FcGoogle /> Continue with Google
                  </>
                )}
              </Button>
            </Stack>

            {/* Footer */}
            <Box as="footer">
              <Stack gap="4" align="center">
                <Text fontSize="sm" color="fg.muted">
                  © {new Date().getFullYear()} Kalmar. All rights reserved.
                </Text>
              </Stack>
            </Box>
          </Stack>
        </Card.Body>
      </Card.Root>
    </Container>
  );
}